import { setDefaultOptions } from "date-fns";
import { enUS, fr } from "date-fns/locale"

export type Locale = "en" | "fr";
export const DEFAULT_LOCALE: Locale = "en";
export const AVAILABLE_LOCALES: Locale[] = ["en", "fr"];
export const CURRENCIES = ["USD", "EUR"];

export const dateFnsLocaleMap = {
  en: enUS,
  fr: fr,  // Add more locales as needed
};

export function setDateFnsLocale(lang: Locale) {
  setDefaultOptions({ locale: dateFnsLocaleMap[lang] || fr });
}